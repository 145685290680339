<template>
  <v-container
    id="user-profile"
    fluid
    tag="section"
  >
    <base-header
      :title="$t('app.rentas_config.name')"
      link="Dashboard"
    />
    <v-row justify="center">
      <v-col
        cols="12"
      >
        <base-material-card color="primary">
          <template v-slot:heading>
            <v-row>
              <v-col class="flex-grow-1 flex-grow-0">
                <div class="display-2 font-weight-light">
                  {{ $t('app.rentas_config.title') }}
                </div>

                <div class="subtitle-1 font-weight-light">
                  {{ $t('app.rentas_config.subtitle') }}
                </div>
              </v-col>
              <v-col class="d-flex align-center flex-grow-0 flex-shrink-0">
                <v-btn
                  dark
                  @click="save"
                >
                  <v-icon
                    dark
                    left
                  >
                    mdi-content-save
                  </v-icon>

                  {{ $t('app.buttons.save') }}
                </v-btn>
              </v-col>
            </v-row>
          </template>

          <v-data-table
            :headers="headers"
            :items="items"
            :items-per-page="10"
            :search="queryClean"
            :custom-filter="customFilter"
          >
            <template v-slot:item.domingo="{ item }">
              <v-text-field v-model="item.domingo" reverse></v-text-field>
            </template>
            <template v-slot:item.lunes="{ item }">
              <v-text-field v-model="item.lunes" reverse></v-text-field>
            </template>
            <template v-slot:item.martes="{ item }">
              <v-text-field v-model="item.martes" reverse></v-text-field>
            </template>
            <template v-slot:item.miercoles="{ item }">
              <v-text-field v-model="item.miercoles" reverse></v-text-field>
            </template>
            <template v-slot:item.jueves="{ item }">
              <v-text-field v-model="item.jueves" reverse></v-text-field>
            </template>
            <template v-slot:item.viernes="{ item }">
              <v-text-field v-model="item.viernes" reverse></v-text-field>
            </template>
            <template v-slot:item.sabado="{ item }">
              <v-text-field v-model="item.sabado" reverse></v-text-field>
            </template>
          </v-data-table>
        </base-material-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import latinize from 'latinize'
import customFilter from "../../../../plugins/customFilter";

  export default {
    name: 'RentasConfig',
    mixins: [customFilter],
    data () {
      return {
        headers: [
          {
            text: this.$t('app.headers.time'),
            value: 'hora',
          },
          {
            text: this.$moment().day(0).format('dddd'),
            value: 'domingo',
          },
          {
            text: this.$moment().day(1).format('dddd'),
            value: 'lunes',
          },
          {
            text: this.$moment().day(2).format('dddd'),
            value: 'martes',
          },
          {
            text: this.$moment().day(3).format('dddd'),
            value: 'miercoles',
          },
          {
            text: this.$moment().day(4).format('dddd'),
            value: 'jueves',
          },
          {
            text: this.$moment().day(5).format('dddd'),
            value: 'viernes',
          },
          {
            text: this.$moment().day(6).format('dddd'),
            value: 'sabado',
          },
        ],
        items: [],
        filters: {
          data: {
            items: [],
          },
          selected: {
            items: [],
            query: '',
          },
        },
      }
    },
    computed: {
      queryClean () {
        return latinize(this.filters.selected.query)
      }
    },
    mounted () {
      this.init()

      EventBus.$on('reload-items', () => {
        this.init()
      })
    },
    methods: {
      async init () {
        this.toggleLoader()

        this.items = await this.getRentasConfig()

        this.toggleLoader()
      },
      async save () {
        this.toggleLoader()

        let params = {
          data: this.items
        }

        await this.$http.post(route('v1/rent-config'), params)
        .then(response => {

        })
      }
    },
  }
</script>

<style scoped>

</style>
