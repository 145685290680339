var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"id":"user-profile","fluid":"","tag":"section"}},[_c('base-header',{attrs:{"title":_vm.$t('app.rentas_config.name'),"link":"Dashboard"}}),_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('base-material-card',{attrs:{"color":"primary"},scopedSlots:_vm._u([{key:"heading",fn:function(){return [_c('v-row',[_c('v-col',{staticClass:"flex-grow-1 flex-grow-0"},[_c('div',{staticClass:"display-2 font-weight-light"},[_vm._v(" "+_vm._s(_vm.$t('app.rentas_config.title'))+" ")]),_c('div',{staticClass:"subtitle-1 font-weight-light"},[_vm._v(" "+_vm._s(_vm.$t('app.rentas_config.subtitle'))+" ")])]),_c('v-col',{staticClass:"d-flex align-center flex-grow-0 flex-shrink-0"},[_c('v-btn',{attrs:{"dark":""},on:{"click":_vm.save}},[_c('v-icon',{attrs:{"dark":"","left":""}},[_vm._v(" mdi-content-save ")]),_vm._v(" "+_vm._s(_vm.$t('app.buttons.save'))+" ")],1)],1)],1)]},proxy:true}])},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"items-per-page":10,"search":_vm.queryClean,"custom-filter":_vm.customFilter},scopedSlots:_vm._u([{key:"item.domingo",fn:function(ref){
var item = ref.item;
return [_c('v-text-field',{attrs:{"reverse":""},model:{value:(item.domingo),callback:function ($$v) {_vm.$set(item, "domingo", $$v)},expression:"item.domingo"}})]}},{key:"item.lunes",fn:function(ref){
var item = ref.item;
return [_c('v-text-field',{attrs:{"reverse":""},model:{value:(item.lunes),callback:function ($$v) {_vm.$set(item, "lunes", $$v)},expression:"item.lunes"}})]}},{key:"item.martes",fn:function(ref){
var item = ref.item;
return [_c('v-text-field',{attrs:{"reverse":""},model:{value:(item.martes),callback:function ($$v) {_vm.$set(item, "martes", $$v)},expression:"item.martes"}})]}},{key:"item.miercoles",fn:function(ref){
var item = ref.item;
return [_c('v-text-field',{attrs:{"reverse":""},model:{value:(item.miercoles),callback:function ($$v) {_vm.$set(item, "miercoles", $$v)},expression:"item.miercoles"}})]}},{key:"item.jueves",fn:function(ref){
var item = ref.item;
return [_c('v-text-field',{attrs:{"reverse":""},model:{value:(item.jueves),callback:function ($$v) {_vm.$set(item, "jueves", $$v)},expression:"item.jueves"}})]}},{key:"item.viernes",fn:function(ref){
var item = ref.item;
return [_c('v-text-field',{attrs:{"reverse":""},model:{value:(item.viernes),callback:function ($$v) {_vm.$set(item, "viernes", $$v)},expression:"item.viernes"}})]}},{key:"item.sabado",fn:function(ref){
var item = ref.item;
return [_c('v-text-field',{attrs:{"reverse":""},model:{value:(item.sabado),callback:function ($$v) {_vm.$set(item, "sabado", $$v)},expression:"item.sabado"}})]}}])})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }